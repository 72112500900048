exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-abroad-form-js": () => import("./../../../src/pages/abroad-form.js" /* webpackChunkName: "component---src-pages-abroad-form-js" */),
  "component---src-pages-abroadeducationconsultancy-js": () => import("./../../../src/pages/abroadeducationconsultancy.js" /* webpackChunkName: "component---src-pages-abroadeducationconsultancy-js" */),
  "component---src-pages-all-case-studies-js": () => import("./../../../src/pages/all-case-studies.js" /* webpackChunkName: "component---src-pages-all-case-studies-js" */),
  "component---src-pages-all-study-page-js": () => import("./../../../src/pages/all-study-page.js" /* webpackChunkName: "component---src-pages-all-study-page-js" */),
  "component---src-pages-best-canada-p-rconsultant-js": () => import("./../../../src/pages/BestCanadaPRconsultant.js" /* webpackChunkName: "component---src-pages-best-canada-p-rconsultant-js" */),
  "component---src-pages-best-study-abroad-consultant-js": () => import("./../../../src/pages/BestStudyAbroadConsultant.js" /* webpackChunkName: "component---src-pages-best-study-abroad-consultant-js" */),
  "component---src-pages-canada-pr-certified-consultant-js": () => import("./../../../src/pages/CanadaPRCertifiedConsultant.js" /* webpackChunkName: "component---src-pages-canada-pr-certified-consultant-js" */),
  "component---src-pages-canada-pr-consultants-js": () => import("./../../../src/pages/CanadaPrConsultants.js" /* webpackChunkName: "component---src-pages-canada-pr-consultants-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-end-user-license-agreement-js": () => import("./../../../src/pages/end-user-license-agreement.js" /* webpackChunkName: "component---src-pages-end-user-license-agreement-js" */),
  "component---src-pages-exam-preps-js": () => import("./../../../src/pages/exam-preps.js" /* webpackChunkName: "component---src-pages-exam-preps-js" */),
  "component---src-pages-flash-news-js": () => import("./../../../src/pages/flash-news.js" /* webpackChunkName: "component---src-pages-flash-news-js" */),
  "component---src-pages-grievance-redressal-js": () => import("./../../../src/pages/grievance-redressal.js" /* webpackChunkName: "component---src-pages-grievance-redressal-js" */),
  "component---src-pages-healthcare-services-js": () => import("./../../../src/pages/healthcare-services.js" /* webpackChunkName: "component---src-pages-healthcare-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pr-demo-js": () => import("./../../../src/pages/pr-demo.js" /* webpackChunkName: "component---src-pages-pr-demo-js" */),
  "component---src-pages-pr-eligibility-calculator-js": () => import("./../../../src/pages/pr-eligibility-calculator.js" /* webpackChunkName: "component---src-pages-pr-eligibility-calculator-js" */),
  "component---src-pages-pr-eligibility-report-js": () => import("./../../../src/pages/pr-eligibility-report.js" /* webpackChunkName: "component---src-pages-pr-eligibility-report-js" */),
  "component---src-pages-pr-score-js": () => import("./../../../src/pages/pr-score.js" /* webpackChunkName: "component---src-pages-pr-score-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profile-evaluation-js": () => import("./../../../src/pages/profile-evaluation.js" /* webpackChunkName: "component---src-pages-profile-evaluation-js" */),
  "component---src-pages-profile-evaluation-result-js": () => import("./../../../src/pages/profile-evaluation-result.js" /* webpackChunkName: "component---src-pages-profile-evaluation-result-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-program-result-js": () => import("./../../../src/pages/program-result.js" /* webpackChunkName: "component---src-pages-program-result-js" */),
  "component---src-pages-settle-abroad-js": () => import("./../../../src/pages/settle-abroad.js" /* webpackChunkName: "component---src-pages-settle-abroad-js" */),
  "component---src-pages-study-abroad-certified-consultant-js": () => import("./../../../src/pages/StudyAbroadCertifiedConsultant.js" /* webpackChunkName: "component---src-pages-study-abroad-certified-consultant-js" */),
  "component---src-pages-study-abroad-course-details-js": () => import("./../../../src/pages/study-abroad/course-details.js" /* webpackChunkName: "component---src-pages-study-abroad-course-details-js" */),
  "component---src-pages-study-abroad-demo-js": () => import("./../../../src/pages/study-abroad-demo.js" /* webpackChunkName: "component---src-pages-study-abroad-demo-js" */),
  "component---src-pages-study-abroad-js": () => import("./../../../src/pages/study-abroad.js" /* webpackChunkName: "component---src-pages-study-abroad-js" */),
  "component---src-pages-study-abroad-not-found-js": () => import("./../../../src/pages/study-abroad/not-found.js" /* webpackChunkName: "component---src-pages-study-abroad-not-found-js" */),
  "component---src-pages-study-abroad-partner-js": () => import("./../../../src/pages/StudyAbroadPartner.js" /* webpackChunkName: "component---src-pages-study-abroad-partner-js" */),
  "component---src-pages-study-abroad-scholarship-not-found-js": () => import("./../../../src/pages/study-abroad-scholarship/not-found.js" /* webpackChunkName: "component---src-pages-study-abroad-scholarship-not-found-js" */),
  "component---src-pages-study-abroad-scholarship-scholarship-search-js": () => import("./../../../src/pages/study-abroad-scholarship/scholarship-search.js" /* webpackChunkName: "component---src-pages-study-abroad-scholarship-scholarship-search-js" */),
  "component---src-pages-study-abroad-scholarship-scholarships-js": () => import("./../../../src/pages/study-abroad-scholarship/scholarships.js" /* webpackChunkName: "component---src-pages-study-abroad-scholarship-scholarships-js" */),
  "component---src-pages-study-abroad-scholarship-view-details-js": () => import("./../../../src/pages/study-abroad-scholarship/view-details.js" /* webpackChunkName: "component---src-pages-study-abroad-scholarship-view-details-js" */),
  "component---src-pages-study-abroad-top-universities-js": () => import("./../../../src/pages/study-abroad/top-universities.js" /* webpackChunkName: "component---src-pages-study-abroad-top-universities-js" */),
  "component---src-pages-study-abroad-university-details-js": () => import("./../../../src/pages/study-abroad/university-details.js" /* webpackChunkName: "component---src-pages-study-abroad-university-details-js" */),
  "component---src-pages-study-abroad-university-search-js": () => import("./../../../src/pages/study-abroad/university-search.js" /* webpackChunkName: "component---src-pages-study-abroad-university-search-js" */),
  "component---src-pages-study-abroadconsultation-js": () => import("./../../../src/pages/StudyAbroadconsultation.js" /* webpackChunkName: "component---src-pages-study-abroadconsultation-js" */),
  "component---src-pages-study-marketing-js": () => import("./../../../src/pages/study-marketing.js" /* webpackChunkName: "component---src-pages-study-marketing-js" */),
  "component---src-pages-studyabroadconsultancy-js": () => import("./../../../src/pages/studyabroadconsultancy.js" /* webpackChunkName: "component---src-pages-studyabroadconsultancy-js" */),
  "component---src-pages-success-stories-js": () => import("./../../../src/pages/success-stories.js" /* webpackChunkName: "component---src-pages-success-stories-js" */),
  "component---src-pages-terms-condition-js": () => import("./../../../src/pages/terms_condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-templates-case-studie-js": () => import("./../../../src/templates/case-studie.js" /* webpackChunkName: "component---src-templates-case-studie-js" */),
  "component---src-templates-marketing-page-js": () => import("./../../../src/templates/marketing-page.js" /* webpackChunkName: "component---src-templates-marketing-page-js" */),
  "component---src-templates-masters-page-js": () => import("./../../../src/templates/masters-page.js" /* webpackChunkName: "component---src-templates-masters-page-js" */),
  "component---src-templates-study-page-js": () => import("./../../../src/templates/study-page.js" /* webpackChunkName: "component---src-templates-study-page-js" */)
}


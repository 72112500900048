import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const EvaluateProfile = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiHomeEvaluateProfile: { nodes: EvaluateProfile },
	} = data;

	const { heading, evaluate_profile } = EvaluateProfile[0];

	return (
		<EvaluateProfileStyled>
			<EvaluateProfileWrapperStyled>
				<h3
					key={`heading`}
					id="custom_counselling_heading"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>

				<EvaluateProfileCardWrapperStyled>
					{evaluate_profile.map((list, index) => {
						return (
							<EvaluateProfileCardStyled key={list.id}>
								<img src={list.profile_img.url} alt={list?.profile_img?.name } />
								<GatsbyImage
									image={getImage(list.profile_img?.url)}
									alt={list.profile_img?.name || "img"}
								/>
								<span className="evaluate_profile_name">
									{list.profile_header}
								</span>
								
								<div className="click_arrow">
									<Link to={list.redirect_to}>
										<FontAwesomeIcon
											icon={faChevronRight}
											size="1x"
											className="fa-angle-right"
										/>
									</Link>
								</div>
							</EvaluateProfileCardStyled>
						);
					})}
				</EvaluateProfileCardWrapperStyled>
			</EvaluateProfileWrapperStyled>
		</EvaluateProfileStyled>
	);
};
const EvaluateProfileStyled = styled.div`
	padding: 0 1em;
`;

const EvaluateProfileWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 4em;
	padding-bottom: 10em;
	& h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
	}

	@media (max-width: 500px) {
		gap: 3em;
		& h3 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;

const EvaluateProfileCardWrapperStyled = styled.div`
	display: flex;
	align-items: stretch;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
			gap: 1rem;
			display: grid;
			align-items: stretch;
			grid-template-columns: 1fr 1fr;

	}
	@media (max-width: 317px) {
		gap: 0.4em;
	}
`;

const EvaluateProfileCardStyled = styled.div`
	min-width: 20em;
	max-width: 20em;
	padding: 1em 3.34375em;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	gap: 1em;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	:nth-child(3) {
		grid-row: 2;
		grid-column: 1 / 3;
	}
	.evaluate_profile_name {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #113a68;
	}

	.click_arrow {
		border-radius: 50%;
		width: 40px;
		height: 40px;
		background: #f1f1f1;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
	.fa-angle-right {
		color: #113a68;
		font-size: 1.2rem;
		height: 1em;
	}
	@media (max-width: 500px) {
		min-width: 100%;
		max-width: 100%;
		padding: 1em;
		gap: 0.5em;
		justify-content: space-between;
		& img {
			height: 50px;
			width: 50px;
		}

		.evaluate_profile_name {
			font-size: 0.9rem;
			line-height: 19px;
		}
		&:nth-child(3) {
			min-width: 100%;
			max-width: 100%;
			flex-direction: row;
			justify-content: center;
			gap: 1rem;
		}
		&:nth-child(3) .evaluate_profile_name {
			text-align: left;
		}
	}

	@media (max-width: 353px) {
		min-width: 7em;
		max-width: 7em;

		.evaluate_profile_name {
			font-size: 0.7rem;
		}
	}
	@media (max-width: 317px) {
		& img {
			height: 40px;
			width: 40px;
		}
	}
`;

export const query = graphql`
	{
		allStrapiHomeEvaluateProfile {
			nodes {
				heading
				evaluate_profile {
					id
					profile_header
					redirect_to
					strapi_id
					profile_img {
						url
						name
					}
				}
			}
		}
	}
`;
export default EvaluateProfile;

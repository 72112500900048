import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
const CustomCounselling = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiHomeCustomCounselling: { nodes: CustomCounselling },
	} = data;

	const {
		heading,
		fotter,
		custom_counselling,
		button_name,
		redirect_to,
		pop_up_form,
	} = CustomCounselling[0];

	return (
		<CustomCounsellingStyled>
			<CustomCounsellingWrapperStyled>
				<span
					key={`heading`}
					id="custom_counselling_heading"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>

				<CustomCounsellingOptionsWrapperStyled>
					{custom_counselling.map((list) => {
						return (
							<div key={list.id}>
								<div className="custom_counselling_options option_one">
									<img
										src={list.counselling_img?.url}
										alt="Icon Image"
									/>
									<p>{list.counselling_details}</p>
								</div>
							</div>
						);
					})}
				</CustomCounsellingOptionsWrapperStyled>
				<CustomCounsellingLastHeadingStyled>
					<p>{fotter}</p>
				</CustomCounsellingLastHeadingStyled>

				{!pop_up_form ? (
					<Link to={`/abroad-form?formName=${redirect_to}`}>
						<ScheduleSessionBtnStyled>
							{button_name}
						</ScheduleSessionBtnStyled>
					</Link>
				) : (
					<ScheduleSessionBtnStyled>
						{button_name}
					</ScheduleSessionBtnStyled>
				)}
			</CustomCounsellingWrapperStyled>
		</CustomCounsellingStyled>
	);
};
const CustomCounsellingStyled = styled.section`
	padding-left: 7.5em;
	padding-right: 7.5em;
	padding-bottom: 7.5em;
	@media (max-width: 980px) {
		padding-left: 4.5em;
		padding-right: 4.5em;
	}
	@media (max-width: 730px) {
		padding: 1.75em;
	}
	@media (max-width: 500px) {
		padding: 1.15em 1rem 4rem;
	}
`;
const CustomCounsellingWrapperStyled = styled.div`
	background: #fff0eb;
	border-radius: 32px;
	padding: 3em 7.5em;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	#custom_counselling_heading {
		width: 100%;
		text-align: left;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		color: #fc997c;
	}

	@media (max-width: 1270px) {
		#custom_counselling_heading {
			text-align: center;
		}
	}
	@media (max-width: 730px) {
		padding: 3em;

		#custom_counselling_heading {
			font-size: 2.6rem;
		}
	}
	@media (max-width: 980px) {
		padding: 3em 2.5em;
	}
	@media (max-width: 600px) {
		#custom_counselling_heading {
			font-size: 2rem;
		}
	}
	@media (max-width: 500px) {
		padding: 2em 0.5em;
		#custom_counselling_heading {
			color: #113a68;
			font-size: 1.75rem;
			line-height: 34px;
		}
	}

	@media (max-width: 353px) {
		padding: 2em 1em;
		#custom_counselling_heading {
			font-size: 1.4rem;
		}
	}
`;

const CustomCounsellingOptionsWrapperStyled = styled.div`
	margin-top: 2.75em;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4em;
	flex-wrap: wrap;
	.custom_counselling_options {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1em;
		min-width: 16.375em;
		max-width: 16.375em;
	}
	.custom_counselling_options > p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		color: #396eb0;
	}

	@media (max-width: 600px) {
		.custom_counselling_options > p {
			font-size: 1.15rem;
		}
	}

	@media (max-width: 500px) {
		gap: 2em;
		.custom_counselling_options {
			flex-direction: column;
		}
		.custom_counselling_options > p {
			font-size: 1rem;
			line-height: 19px;
			text-align: center;
			font-weight: 500;
		}

		.option_one {
			order: 2;
		}
		.option_two {
			order: 1;
		}
		.option_three {
			order: 3;
		}
	}

	@media (max-width: 353px) {
		margin-top: 1.75em;
		.custom_counselling_options {
			min-width: 100%;
			max-width: 100%;
		}
	}
`;
const CustomCounsellingLastHeadingStyled = styled.div`
	margin-top: 4.25em;
	width: 100%;
	max-width: 36.875em;
	& p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #113a68;
	}
	@media (max-width: 600px) {
		& p {
			font-size: 1.15rem;
		}
	}
	@media (max-width: 600px) {
		& > p {
			font-size: 1rem;
			line-height: 19px;
		}
	}
	@media (max-width: 315px) {
		margin-top: 2.25em;
		& > p {
			font-size: 0.8rem;
		}
	}
`;
const ScheduleSessionBtnStyled = styled.button`
	outline: none;
	border: none;
	margin-top: 1.5em;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 24px;
	color: #ffffff;
	padding: 1em 2em;
	background: #113a68;
	border-radius: 12px;
	@media (max-width: 500px) {
		font-size: 1rem;
		line-height: 19px;
		padding: 0.90625em 1.84375em;
	}
	@media (max-width: 315px) {
		font-size: 0.8rem;
	}
`;

export const query = graphql`
	{
		allStrapiHomeCustomCounselling {
			nodes {
				id
				heading
				fotter
				button_name
				redirect_to
				pop_up_form
				custom_counselling {
					id
					counselling_details
					counselling_img {
						url
						name
					}
				}
			}
		}
	}
`;
export default CustomCounselling;

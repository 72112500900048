import { useStaticQuery, graphql } from "gatsby";

export const useFormData = () => {
	const { allStrapiForm } = useStaticQuery(
		graphql`
			query formData {
				allStrapiForm {
					nodes {
						form_name
						heading
						subheading
						form {
							... on STRAPI__COMPONENT_INPUT_INPUT {
								id
								input_name
								mandatory
								strapi_id
								place_holder
								strapi_component
								name
							}
							... on STRAPI__COMPONENT_SELECT_OPTION_SELECT_OPTION {
								id
								mandatory
								option_name
								option_value
								strapi_component
								name
							}
						}
					}
				}
			}
		`
	);
	return allStrapiForm.nodes;
};

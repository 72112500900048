import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import VideoPopup from '../components/commonComponent/videoPopup';
import Layout from '../components/layout.component';
import { useProgramSuccessStoriesData } from '../hooks/successStories';
import { useCaseStudyHeadingData } from '../hooks/caseStudyHeading';
import SEO from '../components/seo';
const CaseStudies = () => {
    const [filter, setFilter] = useState('all');
    const [showVideo, setShowVideo] = useState(false);
    const [videoDetails, setVideoDetails] = useState({
        url: '',
        name: '',
    });

    const onClickPlayVideo = (url, name) => {
        setVideoDetails({
            url: url,
            name: name,
        });
        setShowVideo(true);
    };
    const successStories = useProgramSuccessStoriesData();
    const caseStudyHeader = useCaseStudyHeadingData();
    const filterCaseStudyHeading = caseStudyHeader.filter(
        (item) => item.page_type === 'AllSuccessStories'
    );
    const { heading, subheading } = filterCaseStudyHeading[0] ?? '';
    const onClickFilter = (name) => {
        setFilter(name);
    };
    const customSort = (a, b) => {
        if (a.order === null && b.order === null) {
            // If both orders are null, maintain original order
            return 0;
        } else if (a.order === null) {
            // If order of a is null, move it down
            return 1;
        } else if (b.order === null) {
            // If order of b is null, move it down
            return -1;
        } else {
            // Otherwise, sort based on order
            return a.order - b.order;
        }
    };

    // Sort the data
    const sortedData = successStories.sort(customSort);

    // console.log('sortedData', sortedData);
    return (
        <Layout>
            <SEO
                title="Success Stories | Abroad Ninja"
                description="Explore success stories from Abroad Ninja's students who have achieved educational excellence around the world. Be inspired by their achievements and embark on your own path to success today!"
                og_type="website"
                og_url="https://abroadninja.in/success-stories/"
                og_title="Success Stories | Abroad Ninja"
                og_description="Explore success stories from Abroad Ninja's students who have achieved educational excellence around the world. Be inspired by their achievements and embark on your own path to success today!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/success-stories/"
                twitter_title="Success Stories | Abroad Ninja"
                twitter_description="Explore success stories from Abroad Ninja's students who have achieved educational excellence around the world. Be inspired by their achievements and embark on your own path to success today!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/success-stories/"
            />

            <SuccessStoriesStyled>
                <SuccessStoriesWrapperStyled>
                    <div dangerouslySetInnerHTML={{ __html: heading ?? '' }} />
                    <div
                        dangerouslySetInnerHTML={{ __html: subheading ?? '' }}
                    />
                    <SuccessFilterWrapper>
                        <label
                            htmlFor="all"
                            id="all_button"
                            name="all"
                            className={`${filter == 'all' ? 'isActive' : ''}`}
                            onClick={() => onClickFilter('all')}
                        >
                            All
                        </label>

                        <label
                            htmlFor="study_abroad"
                            id="study_abroad_button"
                            onClick={() => onClickFilter('Study Abroad')}
                            className={`${
                                filter == 'Study Abroad' ? 'isActive' : ''
                            }`}
                            name="Study Abroad"
                        >
                            Study Abroad
                        </label>

                        <label
                            htmlFor="permanent_resident"
                            id="canada_button"
                            name="Canada PR"
                            onClick={() => onClickFilter('Canada PR')}
                            className={`${
                                filter == 'Canada PR' ? 'isActive' : ''
                            }`}
                        >
                            Canada PR
                        </label>
                    </SuccessFilterWrapper>
                    <SuccessStoriesPeopleWrapperStyled>
                        {sortedData
                            .filter((data) => {
                                if (filter === 'all' || filter === ' ') {
                                    return (
                                        data.show_on_all_page === true && data
                                    );
                                }
                                return (
                                    data.show_on_all_page === true &&
                                    data.type === filter
                                );
                            })
                            .map((data, index) => {
                                return (
                                    <div
                                        className="good_university_people_card_details"
                                        key={index}
                                    >
                                        <p className="good_university_people_info">
                                            {data.case_studie_info}
                                        </p>
                                        <div className="good_university_people_card_wrapper">
                                            <div
                                                className="good_university_people_card_img_wrapper"
                                                onClick={() =>
                                                    data?.video[0]
                                                        ?.strapi_component ===
                                                    'video-link.video-link'
                                                        ? onClickPlayVideo(
                                                              data?.video[0]
                                                                  ?.video_link,
                                                              data?.video[0]
                                                                  ?.video_name
                                                          )
                                                        : onClickPlayVideo(
                                                              data?.video[0]
                                                                  ?.upload_video
                                                                  ?.url,
                                                              data?.video[0]
                                                                  ?.upload_video
                                                                  ?.name
                                                          )
                                                }
                                            >
                                                <img
                                                    src={data?.thumbnail?.url}
                                                    alt="Success Stories Card Image"
                                                />
                                                <FontAwesomeIcon
                                                    icon={faPlay}
                                                    size="1x"
                                                    className="fa-play"
                                                />
                                            </div>
                                            <div className="good_university_people_bottom_details">
                                                <div className="good_university_people_left_bottom">
                                                    <small className="good_university_people_name">
                                                        {data?.name}
                                                    </small>
                                                    <small className="good_university_people_desgn">
                                                        {data?.desg}
                                                    </small>
                                                </div>
                                                {/* <small className="good_university_people_read_more">
												Read More
											</small> */}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </SuccessStoriesPeopleWrapperStyled>
                    {/* <ViewAllButtonStyle>Load More</ViewAllButtonStyle> */}
                </SuccessStoriesWrapperStyled>
                {showVideo && videoDetails.url != '' && (
                    <VideoPopup
                        setShowVideo={setShowVideo}
                        link={videoDetails.url}
                        name={videoDetails.name}
                    />
                )}
            </SuccessStoriesStyled>
        </Layout>
    );
};

const SuccessFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    #all_button {
        padding: 0.625rem 1.5rem;
    }
    #study_abroad_button {
        padding: 0.625rem 1.25rem;
    }
    #canada_button {
        white-space: nowrap;
        padding: 0.625rem 1.25rem;
    }
    @media (max-width: 500px) {
        padding: 0.8rem 0rem;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        gap: 0.5rem;
    }

    .isActive {
        background-color: #113a68;
        color: #fff0eb;
        transition: 0.2s all;
    }
    label {
        position: relative;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        background: #fff0eb;
        border-radius: 63px;
        cursor: pointer;
    }
    #study_abroad_button {
        min-width: 9.25rem;
    }
    #canada_button {
        /* min-width: 12.1875rem; */
    }
    label::before {
        content: '';
        height: 30px;
        width: 30px;
    }
    padding: 2rem 0.75rem;
`;
const SuccessStoriesPeopleWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.5rem;
    width: 100%;
    margin-bottom: 3rem;
    flex-wrap: wrap;
    .good_university_people_card_details {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.75em;
    }
`;
const SuccessStoriesWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5rem;
    padding: 0.75rem;
    & h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 1rem;
        margin-top: 4rem;
    }
    & h1 > span {
        color: #113a68;
    }
    .good_university_subheading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 4.25rem;
    }

    .good_university_people_info {
        font-size: 1rem;
        line-height: 19px;
        color: var(--dark-blue);
        width: 20em;
    }

    @media (max-width: 541px) {
        & h2 {
            font-size: 2.7rem;
        }
    }
    @media (max-width: 500px) {
        h1 {
            font-weight: 900;
            font-size: 1.75rem;
            line-height: 34px;
            text-align: center;
        }
        & h2 {
            font-size: 1.75rem;
            line-height: 34px;
        }
        .good_university_subheading {
            font-size: 1rem;
            line-height: 19px;
            font-weight: 500;
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 355px) {
        & h2 {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 315px) {
        & h2 {
            font-size: 1.3rem;
        }
        #good_university_subheading {
            font-size: 0.9rem;
        }
    }
`;

const SuccessStoriesStyled = styled.section`
    margin-top: 5rem;
    .good_university_people_name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: var(--dark-blue);
    }
    .good_university_people_desgn {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        color: #889db4;
    }
    .good_university_people_read_more {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #fc997c;
        margin-right: 1.5em;
        cursor: pointer;
    }

    .good_university_people_card_wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        min-width: 21em;
        max-width: 21em;
        height: 15.875em;
        padding: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.75em;
    }
    .good_university_people_card_img_wrapper {
        border-radius: 12px;
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
    }
    .good_university_people_card_img_wrapper img {
        min-width: 100%;
        max-width: 100%;
        height: 11.25em;
        object-fit: cover;
    }
    .good_university_people_bottom_details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .good_university_people_left_bottom {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 0.5em;
    }
    #good_university_people_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.5rem;
        width: 100%;
        margin-bottom: 3rem;
        flex-wrap: wrap;
    }
    .fa-play {
        font-size: 3rem;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    @media (max-width: 390px) {
        .good_university_people_card_details {
            width: 100%;
        }
        .good_university_people_card_wrapper {
            min-width: 100%;
            max-width: 100%;
        }
        .good_university_people_card_img_wrapper {
            min-width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 355px) {
        .good_university_people_card_wrapper {
            min-width: 18rem;
            max-width: 18rem;
        }
        .good_university_people_info {
            font-size: 0.8rem;
        }
        .good_university_people_read_more {
            font-size: 0.9rem;
        }
    }
    @media (max-width: 315px) {
        .good_university_people_card_wrapper {
            min-width: 15rem;
            max-width: 15rem;
        }
        .good_university_people_read_more {
            margin-right: 0rem;
            font-size: 0.8rem;
        }
        .good_university_people_name {
            font-size: 0.8rem;
        }
        .good_university_people_desgn {
            font-size: 0.7rem;
        }
        .good_university_people_info {
            font-size: 0.7rem;
        }
    }
`;

export default CaseStudies;

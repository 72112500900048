import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, useStaticQuery, Link } from "gatsby";

const MultipleTracks = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiHomePlacingCandidatesAbroad: { nodes: HomePlacingCandidatesAbroad },
	} = data;

	const {
		allStrapiComponentCandidatesAbroadCardCandidatesAbroadCard: { nodes: HomePlacingCardCandidatesAbroadCard },
	} = data;

	const { heading, subheading } = HomePlacingCandidatesAbroad[0];

	return (
		<MultipleTracksStyled>
			<MultipleTracksWrapperStyled>
				<p key={`heading`} id="multiple_tracks_heading1" dangerouslySetInnerHTML={{ __html: heading }} />
				<p key={`subheading`} id="multiple_tracks_heading2" dangerouslySetInnerHTML={{ __html: subheading }} />

				<MultipleTracksThreeWrapperStyled>
					{HomePlacingCardCandidatesAbroadCard.map((card, index) => {
						return (
							<MultipleTracksCardWrapperStyled key={index}>
								<img
									src={card.card_image.url}
									alt="Icon Image"
									className="img-fluid class_banner"
									placeholder="blurred"
								/>
								<div className="multiple_tracks_card_wrapper_one">
									<div className="multiple_tracks_card_wrapper_two">
										<span className="sa_wo_sa">{card.card_header}</span>
										<span className="sa_wo_sa_subheading">{card.card_subheader}</span>
									</div>
									<Link to={card.redirect_to}>
										<FontAwesomeIcon
											icon={faCircleChevronRight}
											size="1x"
											className="fa-circle-chevron-right"
										/>
									</Link>
								</div>
							</MultipleTracksCardWrapperStyled>
						);
					})}
				</MultipleTracksThreeWrapperStyled>
			</MultipleTracksWrapperStyled>
		</MultipleTracksStyled>
	);
};
const MultipleTracksStyled = styled.section``;
const MultipleTracksWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 1em;
	padding: 1em;
	padding-bottom: 10em;
	#multiple_tracks_heading1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
	}
	#multiple_tracks_heading2 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 4rem;
	}

	@media (max-width: 500px) {
		#multiple_tracks_heading1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#multiple_tracks_heading2 {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 2.5em;
		}
	}
`;

const MultipleTracksThreeWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 3.5em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
		gap: 1.5em;
	}
`;

const MultipleTracksCardWrapperStyled = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-direction: column;
	gap: 1.5em;
	padding: 1.25em;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	min-width: 21em;
	max-width: 21em;
	.multiple_tracks_card_wrapper_one {
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		gap: 4em;
		width: 100%;
		.fa-circle-chevron-right {
			color: #fc997c;
			font-size: 2.5rem;
			cursor: pointer;
			height: 3.4rem;
		}
	}
	.multiple_tracks_card_wrapper_two {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		gap: 0.25em;
		width: 100%;
	}

	.sa_wo_sa {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 29px;
		color: #113a68;
		white-space: nowrap;
	}
	.sa_wo_sa_subheading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #6a92c4;
	}
	@media (max-width: 500px) {
		.sa_wo_sa_subheading {
			font-size: 0.875rem;
		}

		padding: 0.75em;
		gap: 1em;
	}

	@media (max-width: 370px) {
		min-width: 20em;
		max-width: 20em;
		.multiple_tracks_card_wrapper_one {
			gap: 2em;
		}
	}

	@media (max-width: 315px) {
		min-width: 15.5em;
		max-width: 15.5em;
		gap: 1em;

		.fa-circle-chevron-right {
			font-size: 2rem;
		}
		.sa_wo_sa {
			font-size: 1.2rem;
		}
		.sa_wo_sa_subheading {
			font-size: 0.775rem;
		}
	}
`;

export const query = graphql`
	{
		allStrapiHomePlacingCandidatesAbroad {
			nodes {
				heading
				subheading
			}
		}
		allStrapiComponentCandidatesAbroadCardCandidatesAbroadCard(sort: { fields: strapi_id, order: ASC }) {
			nodes {
				strapi_id
				card_header
				card_subheader
				redirect_to
				card_image {
					url
					name
				}
			}
		}
	}
`;
export default MultipleTracks;

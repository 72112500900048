import React from "react";
import { createGlobalStyle } from "styled-components";

import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
const GlobalStyle = createGlobalStyle`
   /*
    =============== 
    Fonts
    ===============
    */
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
    /*
    ===============
    variables
    ===============
    */

    :root {
        /* font */
        --ff-primary: "Inter", sans-serif;
        --ff-secondary: "Roboto", sans-serif;

        /* color */
        --color-primary: #113A68;
        /* website color */
        --dark-blue: #113A68;
        --muted-blue: #396EB0;
        --light-blue: #3074C9;
        --dark-orange : #FC997C;
        --muted-orange: #FFD6C9;
        --light-orange: #FFF0EB;
        --white-color : #FFFFFF;
        --black-color : #242424;


        /* typography */
        --defaultFontSize: 16px;
        --headingFont: "Inter", sans-serif;
        --bodyFont: "Inter", sans-serif;
        --smallText: 0.8em;
        --extraSmallText: 0.7em;
    }

    /*
    ===============
    Global Styles
    ===============
    */

    *,
    ::after,
    ::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body {
        font-family: var(--ff-primary);
    }

    p {
        margin: 0;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }
    a {
        text-decoration: none;
    }

    html{
        scroll-behavior: smooth;
    }

`;

export const wrapRootElement = ({ element }) => {
	return (
		<>
			<GlobalStyle />
			{element}
		</>
	);
};

import React from "react";
import Layout from "../components/layout.component";
import HomePageIndex from "../components/HomePage/homePageIndex";
import SEO from "../components/seo";
const Index = () => {
	return (
		<Layout>
			<HomePageIndex />
		</Layout>
	);
};
export function Head (){
	return (
		<SEO 
		
		title="Study & Settle Abroad with Abroad Ninja | Your Gateway to Global" 
		description="Discover a world of opportunities for studying & Settel abroad with Abroad Ninja. Explore top universities, scholarships, IELTS to visa guidance, work support and more."
		keywords="study abroad, study abroad courses, study and settle abroad"
		og_type="website"
		og_url="https://abroadninja.in/"
		og_title="Study & Settle Abroad with Abroad Ninja | Your Gateway to Global"
		og_description="Discover a world of opportunities for studying & Settel abroad with Abroad Ninja. Explore top universities, scholarships, IELTS to visa guidance, work support and more."
		og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
		twitter_card="summary_large_image"
		twitter_url="https://abroadninja.in/"
		twitter_title="Study & Settle Abroad with Abroad Ninja | Your Gateway to Global"
		twitter_description="Discover a world of opportunities for studying & Settel abroad with Abroad Ninja. Explore top universities, scholarships, IELTS to visa guidance, work support and more."
		twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
		robots="index, follow"
		children={

			<script type="application/ld+json">
			{`
				{
					"@context": "https://schema.org/",
					"@type": "WebSite",
					"name": "AbroadNinja",
					"url": "https://abroadninja.in/",
					"potentialAction": 
					{
						"@type": "SearchAction",
						"target": "https://abroadninja.in/{search_term_string}",
						"query-input": "required name=search_term_string"
					}
				}
			`}
			</script>
		}/>
	);
}
export default Index;

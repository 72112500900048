import React from "react";
import styled from "styled-components";
import Hero from "./hero";
import FlyAbroadSection from "./flyAbroadSection";
import MultipleTracks from "./multipleTracks";
import OnlyWeCanDoIt from "./onlyWeCanDoIt";
import CustomCounselling from "./customCounselling";
import EvaluateProfile from "./evaluateProfile";
import HundredsOfImpossible from "./hundredsOfImpossible";
const HomePageIndex = () => {
	return (
		<HomePageIndexStyled>
			<Hero />
			<FlyAbroadSection />
			<HundredsOfImpossible />
			<MultipleTracks />
			<OnlyWeCanDoIt />
			<CustomCounselling />
			<EvaluateProfile />
		</HomePageIndexStyled>
	);
};
const HomePageIndexStyled = styled.section`
	background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg");
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100%;
	@media (max-width: 600px) {
		background-image: none;
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg") !important;
	}
`;
export default HomePageIndex;

import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
function OnlyWeCanDoIt() {
	const data = useStaticQuery(query);
	const {
		allStrapiOpenSecret: { nodes: OpenSecret },
	} = data;

	const {
		allStrapiComponentDetailDetail: { nodes: OpenSecretDetail },
	} = data;

	const { heading, subheading } = OpenSecret[0];

	return (
		<OnlyWeCanDoItStyled>
			<OnlyWeCanDoItwrapperStyled>
				<p
					key={`heading`}
					id="only_we_can_do_it_wrapper_heading_1"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>

				<span
					key={`subheading`}
					id="only_we_can_do_it_wrapper_heading_2"
					dangerouslySetInnerHTML={{ __html: subheading }}
				/>

				<OnlyWeCanDoItCardWrappersStyled>
					<OnlyWeCanDoItCardWrapperStyled>
						{OpenSecretDetail.map((detail) => {
							return (
								<div key={detail.id}>
									{detail.strapi_id <= 2 && (
										<div className="only_we_can_do_it_card" key={detail.id}>
											<p
												key={`details`}
												dangerouslySetInnerHTML={{
													__html: detail.detail,
												}}
											/>
										</div>
									)}
								</div>
							);
						})}
					</OnlyWeCanDoItCardWrapperStyled>
					<OnlyWeCanDoItCardWrapperStyled>
						{OpenSecretDetail.map((detail) => {
							return (
								<div key={detail.id}>
									{detail.strapi_id >= 3 && (
										<div className="only_we_can_do_it_card" key={detail.id}>
											<p
												key={`details`}
												dangerouslySetInnerHTML={{
													__html: detail.detail,
												}}
											/>
										</div>
									)}
								</div>
							);
						})}
					</OnlyWeCanDoItCardWrapperStyled>
				</OnlyWeCanDoItCardWrappersStyled>
			</OnlyWeCanDoItwrapperStyled>
		</OnlyWeCanDoItStyled>
	);
}
const OnlyWeCanDoItStyled = styled.section``;
const OnlyWeCanDoItwrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding-bottom: 10em;
	padding-left: 1.25em;
	padding-right: 1.25em;
	@media (max-width : 500px) {
		padding-bottom: 4rem;
	}
	#only_we_can_do_it_wrapper_heading_1 {
		color: #113a68;
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		width: 100%;
		margin-bottom: 0.4em;
	}
	#only_we_can_do_it_wrapper_heading_2 {
		color: #396eb0;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		width: 100%;
		margin-bottom: 4em;
	}

	@media (max-width: 500px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#only_we_can_do_it_wrapper_heading_2 {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 3em;
		}
	}
	@media (max-width: 340px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.65rem;
		}
	}
	@media (max-width: 316px) {
		#only_we_can_do_it_wrapper_heading_1 {
			font-size: 1.35rem;
		}
	}
`;

const OnlyWeCanDoItCardWrappersStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;
	@media (max-width: 500px) {
		gap: 0rem;
	}
`;

const OnlyWeCanDoItCardWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2em;
	flex-wrap: wrap;
	width: 100%;

	.only_we_can_do_it_card {
		background: #fff0eb;
		border-radius: 16px;
		padding: 1.5em 3em;
		width: 100%;
		text-align: center;
	}

	.only_we_can_do_it_card p {
		text-align: center;
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
	}

	@media (max-width: 1040px) {
		.only_we_can_do_it_card {
			min-width: 28.125em;
		}
	}
	@media (max-width: 500px) {
		gap: 1rem;
		display: block;
		.only_we_can_do_it_card {
			min-width: 100%;
			background: #ffffff;
			padding: 0em;
		}
		.only_we_can_do_it_card p {
			margin-bottom: 1rem;
		}
		
	}
	@media (max-width: 340px) {
		.only_we_can_do_it_card {
			min-width: 100%;
		}
		.only_we_can_do_it_card p {
			font-size: 0.8rem;
		}
	}
`;

export const query = graphql`
	{
		allStrapiOpenSecret {
			nodes {
				id
				heading
				subheading
			}
		}
		allStrapiComponentDetailDetail(sort: { fields: strapi_id, order: ASC }) {
			nodes {
				id
				strapi_id
				detail
			}
		}
	}
`;
export default OnlyWeCanDoIt;

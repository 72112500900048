import styled from "styled-components";

export const InputItem = styled.input`
	margin: ${(props) => props.margin || 0};
	padding: 0;
	border-radius: 0.25em;
	width: 100%;
	type: ${(props) => props.type || "text"};
	font-weight: ${(props) => props.fontWeight || "normal"};
	background-color: ${(props) => props.backgroundColor || "#ffffff"};
	color: ${(props) => props.color || "#000000"};
	padding: ${(props) => props.padding || "12px 20px 12px 50px"};
	border: ${(props) => props.border || "1px solid #d2d8d8"};
	box-sizing: border-box
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	-o-appearance: none;
	appearance: none;
	&:focus {
		border-color: #343642;
		box-shadow: 0 0 5px rgba(52, 54, 66, 0.1);
		outline: none;
	}
	::placeholder,
	::-webkit-input-placeholder {
		color: #97b1d2;
		font-size: 1rem;
	}
`;

import React, { useState } from 'react';
import { InputItem } from '../components/commonComponent/input';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useFormData } from '../hooks/form';
import Layout from '../components/layout.component';
import { navigate } from 'gatsby';
import 'react-notifications/lib/notifications.css';
import SEO from '../components/seo';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
const StudyAbroadForm = () => {
    const isBrowser = typeof window !== 'undefined';
    const [isActiveOption, setActiveOption] = useState('');
    const [isActive, setActive] = useState(false);
    const [optionValue, setOptionValue] = useState({});
    const [submitData, setSubmitData] = useState();

    let slug = 'settle-abroad'; //for default
    let tempUrl = {};
    let redirect_to = '/';
    let isMarketing = false;
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);

        for (const [key, value] of url.searchParams.entries()) {
            tempUrl[key] = value;
        }

        slug = url.searchParams.get('formName');
        redirect_to = url.searchParams.get('redirect_to')
            ? `/${url.searchParams.get('redirect_to')}`
            : '/';

        isMarketing = url.searchParams.get('isMarketing');
    }
    let formData = useFormData();
    let inputField = [];
    let selectField = [];
    let optionObj = {};
    let tepObject = {};
    let FilterFormData = formData.filter((data) => data.form_name === slug);

    if (FilterFormData.length === 0) {
        navigate('/');
        return false;
    }

    const { heading, subheading, form } = FilterFormData[0];

    form.forEach((element) => {
        if (element.strapi_component === 'input.input') {
            inputField.push(element);
            tepObject[element.name] = element.mandatory;
        }

        if (element.strapi_component === 'select-option.select-option') {
            tepObject[element.name] = element.mandatory;
            selectField.push(element);
        }
    });

    selectField.forEach((field) => {
        if (optionObj[field.option_name] !== undefined) {
            optionObj[field.option_name].push(field);
        } else {
            optionObj[field.option_name] = [field];
        }
    });

    const openOption = (option) => {
        setActiveOption(option);
        setActive(!isActive);
    };

    const optionCurrentValue = (Value, e) => {
        setOptionValue({
            ...optionValue,
            [isActiveOption]: Value.option_value,
        });
        setSubmitData({
            ...submitData,
            [Value.name]: Value.option_value,
        });
        setActive(!isActive);
    };

    const onChangeInputField = (e) => {
        setSubmitData({
            ...submitData,
            [e.target.name]: e.target.value.trim(),
        });
    };

    const ValidateField = () => {
        let isValid = true;
        Object.keys(tepObject).map((value) => {
            if (tepObject[value] && submitData !== undefined) {
                if (submitData[value] !== undefined && !submitData[value]) {
                    isValid = false;
                }
            }
        });
        return isValid;
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (ValidateField()) {
            let formData = { ...tempUrl, ...submitData };
            await fetch(`https://api.ufaber.com/api/leads-submit/fetch-lead/`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        NotificationManager.success(
                            'Form Submitted Successfully',
                            'success!!'
                        );

                        navigate(redirect_to);
                    }
                    if (data.error === true) {
                        NotificationManager.error(
                            'While Submitting error Occurs',
                            'error!!'
                        );
                    }
                });
        } else {
            NotificationManager.warning(
                'Please Fill Mandatory Fields',
                'Info!!',
                3000
            );
        }
    };

    let mainForm = () => {
        return (
            <>
                <SEO
                    title="Abroad Consultation Services Application Form | Abroad Ninja"
                    description="Complete the study & settel abroad application form with Abroad Ninja. Take the first step towards your international education experience. Submit your details and explore endless opportunities today!"
                    og_type="website"
                    og_url="https://abroadninja.in/abroad-form/"
                    og_title="Abroad Consultation Services Application Form | Abroad Ninja"
                    og_description="Complete the study & settel abroad application form with Abroad Ninja. Take the first step towards your international education experience. Submit your details and explore endless opportunities today!"
                    og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                    twitter_card="summary_large_image"
                    twitter_url="https://abroadninja.in/abroad-form/"
                    twitter_title="Abroad Consultation Services Application Form | Abroad Ninja"
                    twitter_description="Complete the study & settel abroad application form with Abroad Ninja. Take the first step towards your international education experience. Submit your details and explore endless opportunities today!"
                    twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                    robots="index, follow"
                    canonical="https://abroadninja.in/abroad-form/"
                />
                <MainFormStyle>
                    <SettleAbroadFormWrapper id="settle_abroad_form_wrapper">
                        <h1 dangerouslySetInnerHTML={{ __html: heading }} />
                        <p
                            id="settle_abroad_subheading"
                            dangerouslySetInnerHTML={{ __html: subheading }}
                        />

                        <FormWrapper id="form_wrapper">
                            <form>
                                {inputField.map((field) => (
                                    <>
                                        <label
                                            htmlFor=""
                                            className={`${
                                                tepObject[field.name]
                                                    ? 'required'
                                                    : ''
                                            }`}
                                        >
                                            {field.input_name}
                                        </label>
                                        {field.name === 'phone' ? (
                                            <div className="phone_container">
                                                <InputItem
                                                    type="text"
                                                    className="input_box phone_code"
                                                    padding={'1rem'}
                                                    border={'none'}
                                                    margin={'0px 0px 1rem 0px'}
                                                    backgroundColor={'#F4F4F4'}
                                                    value={'+91'}
                                                    readOnly
                                                />
                                                <InputItem
                                                    type="number"
                                                    className="input_box phone_number"
                                                    padding={'1rem'}
                                                    border={'none'}
                                                    name={field.name}
                                                    margin={'0px 0px 1rem 0px'}
                                                    backgroundColor={'#F4F4F4'}
                                                    placeholder={
                                                        field.place_holder
                                                    }
                                                    onChange={(e) =>
                                                        onChangeInputField(e)
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <InputItem
                                                type={'text'}
                                                className="input_box"
                                                padding={'1rem'}
                                                border={'none'}
                                                name={field.name}
                                                margin={'0px 0px 1rem 0px'}
                                                backgroundColor={'#F4F4F4'}
                                                placeholder={field.place_holder}
                                                onChange={(e) =>
                                                    onChangeInputField(e)
                                                }
                                            />
                                        )}
                                    </>
                                ))}

                                {Object.keys(optionObj).map((option) => {
                                    return (
                                        <>
                                            <label
                                                htmlFor=""
                                                className={`${
                                                    tepObject[
                                                        optionObj[option][0]
                                                            .name
                                                    ]
                                                        ? 'required'
                                                        : ''
                                                }`}
                                            >
                                                {option}
                                            </label>
                                            <SelectStyle
                                                onClick={() =>
                                                    openOption(option)
                                                }
                                            >
                                                <span className="selectMenuButton">
                                                    <small
                                                        className={`selectMenuButtonText ${
                                                            optionValue[
                                                                option
                                                            ] ?? 'notSelected'
                                                        }`}
                                                    >
                                                        {optionValue[option] ??
                                                            'Select Any'}
                                                    </small>

                                                    <FontAwesomeIcon
                                                        icon={faAngleDown}
                                                    />
                                                </span>

                                                <DropDownOption
                                                    className={`${
                                                        isActive &&
                                                        isActiveOption ===
                                                            option
                                                            ? 'active'
                                                            : ''
                                                    }`}
                                                >
                                                    {optionObj[option].map(
                                                        (optionList) => (
                                                            <>
                                                                <li
                                                                    className="selectMenuDropdownOptn"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        optionCurrentValue(
                                                                            optionList,
                                                                            e
                                                                        )
                                                                    }
                                                                >
                                                                    <p
                                                                        className="selectMenuDropdownOptn-text"
                                                                        name={
                                                                            option
                                                                        }
                                                                    >
                                                                        {
                                                                            optionList.option_value
                                                                        }
                                                                    </p>
                                                                </li>
                                                            </>
                                                        )
                                                    )}
                                                </DropDownOption>
                                            </SelectStyle>
                                        </>
                                    );
                                })}

                                <button id="form_submit_btn" onClick={onSubmit}>
                                    Submit
                                </button>
                            </form>
                        </FormWrapper>
                    </SettleAbroadFormWrapper>
                    <NotificationContainer />
                </MainFormStyle>
            </>
        );
    };
    return !isMarketing ? <Layout>{mainForm()}</Layout> : mainForm();
};
const MainFormStyle = styled.div`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    padding: 10rem 0.75rem;

    #form_submit_btn {
        background: #fc997c;
        border-radius: 8px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
        padding: 1rem;
        cursor: pointer;
    }
`;
const SettleAbroadFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }
    #settle_abroad_subheading {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 4.5rem;
        max-width: 36.4375rem;
    }

    @media (max-width: 500px) {
        align-items: flex-start;

        h1 {
            font-weight: 900;
            font-size: 1.5rem;
            line-height: 29px;
            margin-bottom: 0.5rem;
        }
        #settle_abroad_subheading {
            text-align: left;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
            margin-bottom: 1.5rem;
        }
    }
`;

const FormWrapper = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 3.5rem;
    max-width: 40rem;
    min-width: 40rem;

    .required::after {
        content: '*';
        color: #dd1e1e;
    }

    form {
        display: flex;
        flex-direction: column;
    }
    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #396eb0;
        margin-bottom: 0.5rem;
    }
    @media (max-width: 500px) {
        box-shadow: none;
        border-radius: 0px;
        padding: 0rem !important;
    }

    @media (max-width: 690px) {
        max-width: 100%;
        padding: 1.5rem;
        min-width: 100%;
    }
    .phone_container {
        display: flex;
    }
    .phone_code {
        width: 13%;
        border-radius: 0.25em 0 0 0.25em;
        &:focus {
            box-shadow: none;
        }
    }

    .phone_number {
        &:focus {
            box-shadow: none;
        }
    }
    @media (max-width: 500px) {
        .phone_code {
            width: 22%;
        }
    }
`;

const SelectStyle = styled.div`
    width: 100%;
    margin-bottom: 1rem;
    .active {
        display: block;
    }
    .selectMenuButton {
        display: flex;
        background-color: #f4f4f4;
        padding: 0.90625rem 1rem;
        border-radius: 8px;
        align-items: center;
        cursor: pointer;
        justify-content: space-between;
        border: none;
        outline: none;
        width: 100%;
        max-height: 3rem;
        min-height: 3rem;
        .selectMenuButtonText {
            font-size: 1rem;
            line-height: 19px;
            color: black;
        }
        .notSelected {
            color: #97b1d2 !important;
        }
        svg {
            font-size: 0.8rem;
            color: #bbc6d1;
            transition: 0.3s;
        }
    }
`;

const DropDownOption = styled.ul`
    margin-top: 10px;
    display: none;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 10%);
    border-radius: 8px;
    .selectMenuDropdownOptn {
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        align-items: center;
        .selectMenuDropdownOptn-text {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
            color: #113a68;
            padding: 0.7rem 0.5rem;
            width: 100%;
        }
    }
    .selectMenuDropdownOptn-text:hover {
        background: #f2f2f2;
    }
`;
export default StudyAbroadForm;

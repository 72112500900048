import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
const FlyAbroadSection = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiHomeReportCard: { nodes: ReportCard },
	} = data;

	return (
		<FlyAbroadSectionStyled>
			<FlyAbroadWrapperStyled>
				<h2>We have helped thousands fly abroad</h2>
				<span className="our-report-card">Key Highlights</span>
				<DetailsWrapperStyled>
					{ReportCard.map((report) => {
						return (
							<IndividualDetailStyled key={report.id}>
								<span className="detail_one">
									{report.heading}
								</span>
								<span className="detail_two">
									{report.subheading}
								</span>
							</IndividualDetailStyled>
						);
					})}
				</DetailsWrapperStyled>
			</FlyAbroadWrapperStyled>
		</FlyAbroadSectionStyled>
	);
};

const FlyAbroadSectionStyled = styled.section``;
const FlyAbroadWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2.5em 2.8125em 10.75em;
	& h2 {
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
		max-width: 11em;
	}

	.our-report-card {
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 36px;
		width: 100%;
	}

	@media (max-width: 990px) {
		padding: 1.5em;
	}

	@media (max-width: 600px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		.our-report-card {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 500px) {
		padding: 1em 1rem 4rem;
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
		}
		.our-report-card {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 359px) {
		& h2 {
			font-size: 1.4rem;
			line-height: 30px;
		}
		.our-report-card {
			font-size: 0.85rem;
		}
	}
`;

const DetailsWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	gap: 3.5em;
	max-width: 69em;
	.individual_detail {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 0.5em;
	}

	.detail_one {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #fc997c;
	}
	.detail_two {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}

	@media (max-width: 1200px) {
		flex-wrap: wrap;
		gap: 2em;
	}

	@media (max-width: 1080px) {
		gap: 2em;
	}
	@media (max-width: 990px) {
		gap: 3em;
	}
	@media (max-width: 441px) {
		gap: 2em;
	}
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 1.5rem;
	}
`;

const IndividualDetailStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.5em;
	.detail_one {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #fc997c;
	}
	.detail_two {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}

	@media (max-width: 1080px) {
		.detail_one {
			font-size: 1.7rem;
		}
		.detail_two {
			font-size: 0.9rem;
		}
	}

	@media (max-width: 990px) {
		min-width: 11em;
	}
	@media (max-width: 500px) {
		.detail_one {
			font-size: 1.25rem;
			line-height: 24px;
		}
		.detail_two {
			font-size: 0.875rem;
			line-height: 17px;
		}
		&:nth-child(5) {
			flex: 0 0 100%;
		}
		&:nth-child(6) {
			flex: 0 0 100%;

		}
	}


	@media (max-width: 416px) {
		min-width: 100%;
	}
	@media (max-width: 404px) {
		.detail_two {
			font-size: 0.76rem;
		}
	}
	@media (max-width: 359px) {

		.detail_one {
			font-size: 1.3rem;
		}

	}
	@media (max-width: 320px) {

		.detail_one {
			font-size: 1rem;
		}
		.detail_two {
			font-size: 0.7rem;
		}
	}
`;

export const query = graphql`
	{
		allStrapiHomeReportCard(sort: { fields: strapi_id, order: ASC }) {
			nodes {
				id
				heading
				subheading
				strapi_id
			}
		}
	}
`;
export default FlyAbroadSection;

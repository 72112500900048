import React from "react";
import styled from "styled-components";
import { graphql, Link, useStaticQuery } from "gatsby";

import { TypewriterHeader } from "../../utils";
const Hero = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiHomeHero: { nodes: HomeHero },
	} = data;
	const { heading, subheading, button_name, pop_up_form, redirect_to } = HomeHero[0];
	const newHeader = TypewriterHeader(heading);

	return (
		<HeroSectionStyled>
			<h1 key={`heading`} dangerouslySetInnerHTML={{ __html: newHeader }} />

			<p key={`subheading`} id="hero_section_subheading" dangerouslySetInnerHTML={{ __html: subheading }} />
			<div>
				{!pop_up_form ? (
					<Link to={`abroad-form?formName=${redirect_to}`}>
						<button id="get_started">{button_name}</button>
					</Link>
				) : (
					<button id="get_started">{button_name}</button>
				)}
			</div>
		</HeroSectionStyled>
	);
};

const HeroSectionStyled = styled.section`
	padding: 10em 1em 10.375em 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 0.3em;
		max-width: 14.875em;
	}
	& h1 span {
		color: #113a68;
	}
	#hero_section_subheading {
		margin-bottom: 4.5em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		max-width: 26em;
	}
	#get_started {
		font-family: "Inter";

		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #ffffff;
		border-radius: 0.75em;
		background: #113a68;
		padding: 1.25em 2.5em;
		border: none;
		outline: none;
		cursor: pointer;
	}

	@media (max-width: 600px) {
		& h1 {
			font-size: 1.75rem;
			line-height: 2.125rem;
			max-width: 13.875em;
		}
		#hero_section_subheading {
			font-size: 1rem;
			line-height: 1.1875em;
			max-width: 20em;
			margin-bottom: 3.5em;
		}
		#get_started {
			font-size: 1rem;
			line-height: 1.1875em;
			padding: 0.9em 1.75em;
		}
	}

	@media (max-width: 370px) {
		& h1 {
			font-size: 1.6rem;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 340px) {
		& h1 {
			font-size: 1.4rem;
			line-height: 1.7rem;
			max-width: 13.875em;
			margin-bottom: 0.4em;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
		#get_started {
			font-size: 0.9rem;
			padding: 0.9em 1.2em;
		}
	}
`;

export const query = graphql`
	{
		allStrapiHomeHero {
			nodes {
				strapi_id
				heading
				subheading
				button_name
				pop_up_form
				redirect_to
			}
		}
	}
`;
export default Hero;
